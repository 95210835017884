/* eslint-disable global-require */

import _ from 'lodash';
import Store from '@/store/index';
import { Message } from 'element-ui';

function setConfigs() {
    const DISEASE = window.location.pathname.split('/')[1];
    const REQ = require(`@/config/${DISEASE}/index`);

    const DISABLE_PAGE = REQ.default.plugins;
    const VIEW = REQ.default.view;
    const QUESTION = REQ.default.question;
    const LEADER_BOARD = REQ.default.leaderBoard;
    const EXCEL_LABEL = REQ.default.excelLabel;
    const FILTER_TAG = REQ.default.filterTag;
    const DEFAULT_PAGE = REQ.default.defaultPage;

    Store.commit('config/setPlugins', DISABLE_PAGE);
    Store.commit('config/setView', VIEW);
    Store.commit('config/setQuestion', QUESTION);
    Store.commit('config/setLeaderBoard', LEADER_BOARD);
    Store.commit('config/setExcelLabel', EXCEL_LABEL);
    Store.commit('config/setFilterTag', FILTER_TAG);
    Store.commit('config/setDefaultPage', DEFAULT_PAGE);
}

export default {
    data() {
        return {
            disease: '',
            redirect: '',
        };
    },
    created() {
        this.disease = this.$route.params.disease;
        this.setRedirect();
    },
    async beforeRouteEnter(to, from, next) {
        const TOKEN = window.localStorage.getItem('token');
        const DISEASE = window.location.pathname.split('/')[1];
        let RESULT;
        if (TOKEN) {
            Store.commit('api/setToken', TOKEN);
            Store.commit('api/setDisease', DISEASE);
            const PAYLOAD = {
                category: 'Auth',
                function: 'verifyJwtToken',
                // successMsg: '歡迎回來',
            };
            RESULT = await Store.dispatch('api/invoke', PAYLOAD);
        } else {
            // startLoginFlow
            let ACCESS_TOKEN;
            if (window.location.hash) {
                const matches = window.location.hash.match(
                    'access_token=(?<token>.*?)&',
                );
                if (matches && matches.groups) {
                    ACCESS_TOKEN = matches.groups.token;
                }
            }

            const isInIframe = window.frames.length !== window.parent.frames.length;
            if (isInIframe) {
                window.location.href = `${window.location.origin}/${DISEASE}/clm-login`;
                return;
            } if (!ACCESS_TOKEN) {
                // startGoogleLogin
                next((vm) => {
                    vm.startGoogleLogin();
                });
            } else {
                // startVerifyOAuthToken
                Store.commit('api/setDisease', DISEASE);

                const SUBMIT = { token: ACCESS_TOKEN };
                const PAYLOAD = {
                    category: 'Auth',
                    function: 'verifyOauthToken',
                    // successMsg: '登入成功',
                    data: SUBMIT,
                };
                RESULT = await Store.dispatch('api/invoke', PAYLOAD);
            }
        }
        if (!RESULT) {
            Store.dispatch('auth/clearUserInformation');
            window.localStorage.clear();
            const isInIframe = window.frames.length !== window.parent.frames.length;
            if (isInIframe) {
                window.location.href = `${window.location.origin}/${DISEASE}/clm-login`;
            } else {
                next((vm) => {
                    vm.$router.push({ name: 'Login' });
                    setTimeout(() => {
                        vm.startGoogleLogin();
                    }, 5000);
                });
            }
            return;
        }
        const DATA = {
            user: RESULT.data.accountData,
            token: RESULT.data.token,
        };
        Store.dispatch('auth/setUserInformation', DATA);
        Store.commit('api/setToken', RESULT.data.token);
        // if (window && window.parent) {
        //     const message = { command: 'setToken', token: RESULT.data.token };
        //     window.parent.postMessage(message, '*');
        // }
        setConfigs();

        next((vm) => {
            const ACCOUNT_AUTH = RESULT.data.accountData.AccountAuths;
            const DISEASE_AUTH = _.find(ACCOUNT_AUTH, { DiseaseId: DISEASE });
            const isIdentityMatch = DISEASE_AUTH.auth === vm.redirect;
            const isSales = DISEASE_AUTH.auth === 'sales';
            if (isSales && !isIdentityMatch) {
                Store.dispatch('auth/clearUserInformation');
                vm.$router.push({ name: 'Login' });
                Message({
                    message: '身份錯誤，請重新登入',
                    type: 'error',
                });
                setTimeout(() => {
                    vm.startGoogleLogin();
                }, 5000);
            } else {
                window.history.replaceState(
                    {},
                    '',
                    `${window.location.origin}${vm.$route.path}`,
                );
            }
        });
    },
    methods: {
        setRedirect() {
            const PATH = this.$route.path;
            if (PATH.match(/manager/)) this.redirect = 'manager';
            else if (PATH.match(/sales/)) this.redirect = 'sales';
        },
        async startGoogleLogin() {
            const PARAMETERS = {
                client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                scope: 'profile email',
                redirect_uri: `${process.env.VUE_APP_BASE_URL}${this.disease}/${this.redirect}`,
                response_type: 'token',
                prompt: 'select_account',
            };
            const queryString = _.map(PARAMETERS, (v, k) => `${k}=${v}`).join(
                '&',
            );
            window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${queryString}`;
        },
    },
};
