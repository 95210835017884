<template>
    <pop-up background-color="rgba(0, 0, 0, 0.7)" :on-close="onCancel">
        <div class="text">
            <div class="title">轉移文章</div>
        </div>
        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="form"
            class="form"
            inline
        >
            <div class="input-block">
                <div class="label">由此PJP轉移</div>
                <el-form-item fluid class="input" prop="from">
                    <el-select
                        style="width: 100%"
                        v-model="ruleForm.from"
                        placeholder="請選擇"
                        @change="onChangeFrom"
                    >
                        <el-option
                            v-for="option in fromList"
                            :key="option.id"
                            :label="option.email"
                            :value="option.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="input-block">
                <div class="label">轉移至此PJP</div>
                <el-form-item fluid class="input" prop="to">
                    <el-select
                        style="width: 100%"
                        v-model="ruleForm.to"
                        placeholder="請選擇"
                        :disabled="!ruleForm.from"
                    >
                        <el-option
                            v-for="option in toList"
                            :key="option.id"
                            :label="option.email"
                            :value="option.id"
                        >
                            {{ option.email }}
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
        <div class="button-set">
            <button-wrapper type="modal" color="primary" @click="onConfirm">
                確定
            </button-wrapper>
        </div>
    </pop-up>
</template>

<script>
import PopUp from '@/components/Popup/PopUpWrapper.vue';
import { mapState, mapMutations, mapActions } from 'vuex';

const LIMIT = 999;

export default {
    components: { PopUp },
    computed: {
        ...mapState({
            target: (state) => state.manager.target,
        }),
        isEditMode() {
            return !!this.target;
        },
    },
    created() {
        this.onSearch();
    },
    data() {
        return {
            totalList: [],
            fromList: [],
            toList: [],

            ruleForm: {
                from: '',
                to: '',
            },
            rules: {
                from: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                ],
                to: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    methods: {
        ...mapMutations({
            updateTransferCasePopUpState: 'popup/updateTransferCasePopUpState',
        }),
        ...mapActions({
            transferCase: 'manager/transferCase',
            apiClient: 'api/invoke',
        }),
        async onConfirm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const SUBMIT = {
                        from: this.ruleForm.from,
                        to: this.ruleForm.to,
                    };
                    this.transferCase(SUBMIT);
                    this.onCancel();
                    return true;
                }
                return false;
            });
        },
        onChangeFrom(id) {
            const FILTER = this.totalList.filter((item) => item.id !== id);
            this.toList = [];
            this.ruleForm.to = '';
            this.toList.push(...FILTER);
        },
        async onSearch() {
            const PAYLOAD = {
                category: 'Account',
                function: 'searchSales',
                data: `?limit=${LIMIT}&page=${1}`,
            };
            const RES = await this.apiClient(PAYLOAD);
            this.totalList.push(...RES.data);
            this.fromList.push(...this.totalList);
        },
        onCancel() {
            this.updateTransferCasePopUpState(false);
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    color: #000000;
    font-size: 24px;
    line-height: 35px;
}
.form {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 50px;
    .input-block {
        margin-top: 20px;
        display: flex;
        align-items: center;
    }
    .label {
        width: 120px;
        margin-right: 20px;
    }
    .input {
        width: calc(100% - 100px);
        margin-right: 0;
    }
    .el-form-item__label,
    .el-form-item__content {
        font-size: 18px !important;
    }
    .el-form--inline .el-form-item {
        margin-right: 0;
    }
}
</style>
