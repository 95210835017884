<template>
    <pop-up
        background-color="rgba(0, 0, 0, 0.7)"
        :on-close="onCancel"
        height="400px"
    >
        <div class="text">
            <div class="title">PJP資料</div>
        </div>
        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="form"
            class="form"
            inline
        >
            <div class="input-block">
                <div class="label">PJP名字</div>
                <el-form-item fluid class="input" prop="name">
                    <el-input
                        style="width: 100%"
                        v-model="ruleForm.name"
                        placeholder="請輸入姓名"
                    ></el-input>
                </el-form-item>
            </div>
            <div class="input-block">
                <div class="label">登入信箱</div>
                <el-form-item fluid class="input" prop="email">
                    <el-input
                        style="width: 100%"
                        v-model="ruleForm.email"
                        placeholder="請輸入信箱"
                    ></el-input>
                </el-form-item>
            </div>
            <div class="input-block">
                <div class="label">主管信箱</div>
                <el-form-item fluid class="input" prop="managerEmail">
                    <el-input
                        style="width: 100%"
                        v-model="ruleForm.managerEmail"
                        placeholder="請輸入信箱"
                    ></el-input>
                </el-form-item>
            </div>
            <div class="input-block">
                <div class="label">密碼</div>
                <el-form-item fluid class="input" prop="password">
                    <el-input
                        style="width: 100%"
                        v-model="ruleForm.password"
                        placeholder="請輸入密碼"
                    ></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div class="button-set" :class="{ 'space-between': isEditMode }">
            <div v-if="isEditMode" class="button-set">
                <button-wrapper
                    type="modal"
                    color="normal"
                    :width="130"
                    @click="onRenewToken"
                >
                    renew-token
                </button-wrapper>
                <button-wrapper
                    type="modal"
                    color="normal"
                    @click="onDeactivate"
                >
                    停用
                </button-wrapper>
            </div>
            <button-wrapper
                v-if="isEditMode"
                type="modal"
                color="primary"
                @click="onSave"
            >
                儲存
            </button-wrapper>
            <button-wrapper
                v-else
                type="modal"
                color="primary"
                @click="onConfirm"
            >
                確定
            </button-wrapper>
        </div>
    </pop-up>
</template>

<script>
import PopUp from '@/components/Popup/PopUpWrapper.vue';
import { mapState, mapMutations, mapActions } from 'vuex';

const validateEmail = (rule, value, callback) => {
    const REG = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    if (!value.match(REG)) {
        callback(new Error('請輸入正確格式'));
    } else callback();
};

export default {
    components: { PopUp },
    computed: {
        ...mapState({
            target: (state) => state.manager.target,
        }),
        isEditMode() {
            return !!this.target;
        },
    },
    data() {
        return {
            ruleForm: {
                name: '',
                email: '',
                managerEmail: '',
                password: '',
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                ],
                email: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                    { validator: validateEmail },
                ],
                managerEmail: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                    { validator: validateEmail },
                ],
                password: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    created() {
        this.fetchData();
    },
    destroyed() {
        this.setTarget(undefined);
    },
    methods: {
        ...mapMutations({
            setTarget: 'manager/setTarget',
            updateCreateSalesPopUpState: 'popup/updateCreateSalesPopUpState',

            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',
        }),
        ...mapActions({
            createSales: 'manager/createSales',
            updateSales: 'manager/updateSales',
            updateFilter: 'manager/updateFilter',

            apiClient: 'api/invoke',
        }),
        fetchData() {
            if (!this.isEditMode) return;
            this.ruleForm.name = this.target.name;
            this.ruleForm.email = this.target.email;
            this.ruleForm.managerEmail = this.target.managerEmail;
        },
        async onConfirm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const SUBMIT = this.ruleForm;
                    this.createSales(SUBMIT);
                    return true;
                }
                return false;
            });
        },
        async onSave() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const SUBMIT = {
                        id: this.target.id,
                        data: this.ruleForm,
                    };
                    this.updateSales(SUBMIT);
                    return true;
                }
                return false;
            });
        },
        onCancel() {
            this.updateCreateSalesPopUpState(false);
        },
        onRenewToken() {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定Renew Token這個PJP嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'Account',
                    function: 'renewToken',
                    successMsg: 'PJP已Renew Token',
                    data: [this.target.id],
                };
                await this.apiClient(PAYLOAD);
            });
        },
        onDeactivate() {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定停用這個PJP嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'Account',
                    function: 'deactivate',
                    successMsg: 'PJP已停用',
                    data: [this.target.id],
                };
                await this.apiClient(PAYLOAD);
                this.updateFilter();
                this.onCancel();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    color: #000000;
    font-size: 24px;
    line-height: 35px;
}
.form {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .input-block {
        margin-top: 20px;
        display: flex;
        align-items: center;
    }
    .label {
        width: 72px;
        margin-right: 20px;
    }
    .input {
        width: calc(100% - 100px);
        margin-right: 0;
    }
    .el-form-item__label,
    .el-form-item__content {
        font-size: 18px !important;
    }
    .el-form--inline .el-form-item {
        margin-right: 0;
    }
}
.button-set.space-between {
    justify-content: space-between;
}
</style>
