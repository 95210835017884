<template>
    <div class="manager-index index-page" :class="$route.params.disease">
        <router-view />
        <NavigationBar />
        <BottomNavigation />
        <transition name="fade" ease="in-out">
            <CreateSales v-if="createSalesPopUp" />
        </transition>
        <transition name="fade" ease="in-out">
            <TransferCase v-if="transferCasePopUp" />
        </transition>
        <transition name="fade" ease="in-out">
            <RevealInformation v-if="revealInformationPopup" />
        </transition>
    </div>
</template>

<script>
import NavigationBar from '@/components/Navigation/NavigationBar.vue';
import BottomNavigation from '@/components/BottomNavigation/BottomNavigationBar.vue';
import CreateSales from '@/components/Popup/CreateSales.vue';
import TransferCase from '@/components/Popup/TransferCase.vue';
import Login from '@/mixins/Login';
import RevealInformation from '@/components/Popup/RevealInformation.vue';
import { mapState } from 'vuex';

export default {
    components: {
        NavigationBar,
        BottomNavigation,
        CreateSales,
        TransferCase,
        RevealInformation
    },
    mixins: [Login],
    created() {
        if (this.$route.name === 'Manager_Index') this.$router.push({ name: this.defaultPage });
    },
    computed: {
        ...mapState({
            createSalesPopUp: (state) => state.popup.createSalesPopUp,
            transferCasePopUp: (state) => state.popup.transferCasePopUp,
            revealInformationPopup: (state) => state.popup.revealInformationPopup,
            defaultPage: (state) => state.config.defaultPage?.manager,
        }),
    },
};
</script>
